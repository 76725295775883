import React, { useState } from "react";
import { useUIContext } from "../../../context/UIContext";
import InsertStudentsSidebar from "../../../unicomponents/InsertStudentsSidebar";

export default function EbomActivitiesByFieldsActivity({
  rows,
  selectedActivity,
  setSelectedActivity,
  schoolStageId,
  schoolStageOrganPath,
  setFilters,
  studentDataForDuplicateCheck,
  schoolYear,
  createEduActiStudents,
  createEduActiStudentsBulk,
  selectedTerm,
}) {
  const [isAdded, setIsAdded] = useState([]);
  const [isAddedAll, setIsAddedAll] = useState(false);
  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();

  function handleActivityChange(e) {
    const { value } = e.target;
    const selected = rows.filter(
      (row) => row.id.toString() === value.toString()
    );

    setSelectedActivity(selected[0]);
    if (selected.length > 0) {
      setFilters((filters) => ({
        ...filters,
        educational_activities_id: selected[0].id,
      }));
    } else {
      setFilters((filters) => ({
        ...filters,
        educational_activities_id: 0,
      }));
    }
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAddClick = (row) => {
    if (!selectedActivity || Object.keys(selectedActivity).length === 0) {
      // selectedActivity가 null이거나, 빈 객체일 때 실행할 코드
      alert("활동을 먼저 선택해 주세요.");

      return;
    }

    const filteredData = studentDataForDuplicateCheck.filter(
      (r) =>
        r.school_year === schoolYear &&
        r.hierarchy_one === selectedActivity.hierarchy_one
    );
    const dataForDuplicateUser = filteredData.filter(
      (r) => r.educational_activities_id === selectedActivity.id
    );
    if (dataForDuplicateUser.find((r) => r.username === row.username)) {
      alert("이미 배정된 학생입니다.");
      return;
    }

    // tags 배열 내의 각 객체를 순회하며 값을 3으로 설정
    const generatedTags = (selectedActivity.tags || []).map((tagObject) => {
      // 객체 내의 모든 키에 대해 값을 3으로 설정
      const updatedTagObject = {};
      Object.keys(tagObject).forEach((key) => {
        updatedTagObject[key] = "3"; // 값으로 문자열 "3"을 설정, 숫자로 설정하려면 3으로 변경
      });
      return updatedTagObject;
    });

    //데이터베이스에 저장할 데이터 만들기
    const addDataSet = {
      schoolid: selectedActivity.schoolid,
      school_year: selectedActivity.school_year,
      educational_activities_id: selectedActivity.id,
      username: row.username,
      title: selectedActivity.title,
      content: selectedActivity.content,
      on_schoollife_record: "등재",
      extra_attributes: {
        hours: selectedActivity.extra_attributes.hours || "",
        field: selectedActivity.extra_attributes.field || "",
        termName: selectedActivity.extra_attributes.termName || "",
      },
      tags: generatedTags,
    };

    setIsLoadingModal(true);
    createEduActiStudents.mutate(addDataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setIsAdded((prev) => [...prev, row.id]);
        setTimeout(() => {
          setIsAdded([]);
        }, 1500);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleAddAllClick = (rows) => {
    if (!selectedActivity || Object.keys(selectedActivity).length === 0) {
      // selectedActivity가 null이거나, 빈 객체일 때 실행할 코드
      alert("활동을 먼저 선택해 주세요.");

      return;
    }

    const bulkDataSets = [];
    const addedStudents = []; // 성공적으로 추가될 학생들의 이름을 저장할 배열
    const duplicateStudents = []; // 중복으로 추가되지 않은 학생들의 이름을 저장할 배열
    let message;

    rows.forEach((row) => {
      const filteredData = studentDataForDuplicateCheck.filter(
        (r) =>
          r.school_year === schoolYear &&
          r.hierarchy_one === selectedActivity.hierarchy_one
      );

      const isDuplicate = filteredData.some(
        (r) =>
          r.educational_activities_id === selectedActivity.id &&
          r.username === row.username
      );

      if (isDuplicate) {
        duplicateStudents.push(row.fullname);
        return;
      }
      const generatedTags = (selectedActivity.tags || []).map((tagObject) => {
        const updatedTagObject = {};
        Object.keys(tagObject).forEach((key) => {
          updatedTagObject[key] = "3";
        });
        return updatedTagObject;
      });

      const addDataSet = {
        schoolid: selectedActivity.schoolid,
        school_year: selectedActivity.school_year,
        educational_activities_id: selectedActivity.id,
        username: row.username,
        title: selectedActivity.title,
        content: selectedActivity.content,
        on_schoollife_record: "등재",
        extra_attributes: {
          hours: selectedActivity.extra_attributes.hours || "",
          field: selectedActivity.extra_attributes.field || "",
          termName: selectedActivity.extra_attributes.termName || "",
        },
        tags: generatedTags,
      };

      addedStudents.push(row.fullname);
      bulkDataSets.push(addDataSet);
    });

    if (bulkDataSets.length > 0) {
      setIsLoadingModal(true);
      createEduActiStudentsBulk.mutate(bulkDataSets, {
        onSuccess: (successMessage) => {
          console.log(successMessage);
          setIsLoadingModal(false);
          // 성공적으로 추가된 row.id들을 setIsAddedAll로 처리
          setIsAddedAll(true);
          setTimeout(() => {
            setIsAddedAll(false);
          }, 1500);

          message = `${
            addedStudents.length
          }명이 성공적으로 추가되었습니다. <br>- ${addedStudents.join(", ")}`;

          if (duplicateStudents.length > 0) {
            message = `${
              addedStudents.length
            }명이 성공적으로 추가되었습니다. <br>- ${addedStudents.join(
              ", "
            )} <br> 다음 ${
              duplicateStudents.length
            }명은 중복으로 인해 추가되지 않았습니다. <br> - ${duplicateStudents.join(
              ", "
            )}`;
          }
          handleToastCenterTop(message, 4000);
        },
        onError: (error) => {
          setIsLoadingModal(false);
          console.error(error);
          alert("에러 발생: " + error.message);
        },
      });
    } else {
      if (duplicateStudents.length > 0) {
        message = `다음 ${
          duplicateStudents.length
        }명은 중복으로 인해 추가되지 않았습니다. <br> - ${duplicateStudents.join(
          ", "
        )}`;
      }
      handleToastCenterTop(message, 4000);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3 className="fw-bold">영역별 학습내용 기록</h3>
        <button
          className="btn btn-sm btn-secondary mt-2"
          onClick={toggleSidebar}
        >
          배정
        </button>
      </div>
      <InsertStudentsSidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        handleAddClick={handleAddClick}
        isAdded={isAdded}
        isAddedAll={isAddedAll}
        handleAddAllClick={handleAddAllClick}
        schoolStageId={schoolStageId}
        schoolStageOrganPath={schoolStageOrganPath}
      />
      <table className="table mb-1">
        <thead>
          <tr className="table-secondary">
            <th style={{ width: "8%" }}>과정</th>
            <th style={{ width: "25%" }}>활동명</th>
            <th style={{ width: "8%" }}>영역</th>
            <th style={{ width: "7%" }}>학기</th>
            <th style={{ width: "8%" }}>시간</th>
            <th style={{ width: "44%" }}>세부내용</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{selectedActivity?.schoolStage}</td>
            <td>
              <select
                className="select-like-span"
                name="educational_activities"
                value={selectedActivity?.id || ""}
                onChange={handleActivityChange}
              >
                <option value="0">선택</option>
                {rows &&
                  rows
                    ?.filter(
                      (r) =>
                        r.extra_attributes.termName === selectedTerm.toString()
                    )
                    .map((row, index) => (
                      <option key={index} value={row.id}>
                        {row.title}
                      </option>
                    ))}
              </select>
            </td>
            <td>{selectedActivity?.extra_attributes?.field}</td>
            <td>{selectedActivity?.extra_attributes?.termName}</td>
            <td>{selectedActivity?.extra_attributes?.hours}</td>
            <td>{selectedActivity?.content}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
