import React, { useEffect, useState } from "react";
import HelpHyperLink from "../../../../unicomponents/HelpHyperLink";
import { useUIContext } from "../../../../context/UIContext";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import useApprovalGroups from "../../../../hooks/useApprovalGroups";
import InsertApprovalMembers from "./InsertApprovalMembers";
import useApprovalGroupMembers from "../../../../hooks/useApprovalGroupMembers";

/* 
고정결재는 고정결재(document_form) 양식을 만들면 해당학교의 것이 결재그룹에 보이고, 
그 document_label이 approval_groups의 group_name이 된다. 
document_form을 만들 때 approval_group도 만들어져야 한다.
1단계는 담당자이고, 2, 3, 4... 단계 결재자 설정 가능 -> 새 모달로
전결도 설정 가능
고정결재는 order를 0으로 하고 0일 경우엔 수정의 저장/삭제가 안되도록, 
그리고 새로 만드는 것도 order가 1보다 작으면 안되도록 하면 되겠다. 
결재 멤버 바로 보이도록 하고, 관리 누르면 모달 떠서 수정할 수 있도록
*/

export default function ApprovalSetting() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];

  const conditions = {
    schoolid: schoolId,
  };

  const [dataToCreate, setDataToCreate] = useState({
    schoolid: schoolId,
    order: "",
    group_name: "",
    description: "",
  });

  const [isAdded, setIsAdded] = useState(false);
  const [dataToSaveRows, setDataToSaveRows] = useState([]);
  const [savedRows, setSavedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState([]);

  //InsertGroupMembersModal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const [groupId, setGroupId] = useState("");
  const [groupName, setGroupName] = useState("");

  const {
    approvalGroupsQuery: { data: approvalGroupsData },
    createApprovalGroups,
    updateApprovalGroups,
    deleteApprovalGroups,
  } = useApprovalGroups(conditions);

  const {
    approvalGroupMembersQuery: { data: approvalGroupMembersData },
  } = useApprovalGroupMembers({ schoolid: schoolId });

  const { handleToastCenterTop, setIsLoadingModal } = useUIContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    setDataToSaveRows(approvalGroupsData);
  }, [approvalGroupsData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataToCreate((prev) => ({
      ...prev,
      [name]: value,
    }));

    // console.log("dataToCreate", dataToCreate);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (dataToCreate.order < 1) {
      handleToastCenterTop("순서를 1이상으로 입력해 주세요.");
      return;
    }
    // description을 trim
    dataToCreate.description = dataToCreate.description.trim();

    // console.log("dataToCreate", dataToCreate);

    setIsLoadingModal(true);
    createApprovalGroups.mutate(dataToCreate, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setIsAdded(true);
        setTimeout(() => {
          setIsAdded(false);
        }, 1500);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleTrChange = (rowIndex, event) => {
    const { value } = event.target;
    const field = event.target.name;
    const updatedRows = dataToSaveRows.map((row, index) =>
      index === rowIndex ? { ...row, [field]: value, isModified: true } : row
    );
    // console.log("updatedRows tr", updatedRows);
    setDataToSaveRows(updatedRows);
  };

  const handleSave = (index) => {
    let dataSet = dataToSaveRows[index];

    if (!dataSet.isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      handleToastCenterTop("변경된 사항이 없습니다.");
      console.log("건너뛰기");
      return;
    }

    if (dataSet.order < 1) {
      handleToastCenterTop("순서는 1 이상이어야 합니다.");
      return;
    }

    if (!dataSet.order) {
      handleToastCenterTop("순서에 데이터가 있어야 합니다.");
      return;
    }

    if (!dataSet.group_name) {
      handleToastCenterTop("그룹 이름에 데이터가 있어야 합니다.");
      return;
    }

    // description을 trim
    dataSet.description = dataSet.description.trim();

    // console.log("dataSet", dataSet);
    setIsLoadingModal(true);
    updateApprovalGroups.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, dataSet.id]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
    // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
    setDataToSaveRows((currentDataSets) =>
      currentDataSets.map((dataSet, dataSetIndex) =>
        dataSetIndex === index ? { ...dataSet, isModified: false } : dataSet
      )
    );
  };

  const handleConfirmDelete = (row) => {
    setConfirmDelete((prev) => [...prev, row.id]);
  };

  const handleCancelDelete = () => {
    setConfirmDelete([]);
  };

  const handleDelete = (row) => {
    const id = row.id;
    // console.log("delete id", id);
    setIsLoadingModal(true);
    deleteApprovalGroups.mutate(id, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setConfirmDelete([]);
        queryClient.invalidateQueries(["approvalGroups"]);
        setDataToSaveRows((rows) => rows.filter((row) => row.id !== id));
      },
      onError: (error) => {
        setIsLoadingModal(false);
        setConfirmDelete([]);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleMembers = (e) => {
    const { id } = e.target;
    setGroupId(id);
    const selectedRow = dataToSaveRows.find(
      (row) => row.id.toString() === id.toString()
    );
    if (selectedRow) {
      setGroupName(selectedRow.group_name);
    }
    toggleModal();
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-auto">
          <h3 className="fw-bold">
            결재그룹 설정 <HelpHyperLink link={""} />
          </h3>
        </div>
      </div>
      <InsertApprovalMembers
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        groupId={groupId}
        schoolId={schoolId}
        groupName={groupName}
      />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div
            className="col-4 col-sm-3 col-md-2 col-xl-1"
            style={{ minWidth: "100px" }}
          >
            <label htmlFor="order" className="form-label ms-2 fw-bold">
              순서
            </label>
            <input
              type="number"
              name="order"
              className="form-control"
              placeholder="1이상만 가능"
              required
              value={dataToCreate.order || ""}
              onChange={handleChange}
            />
          </div>

          <div
            className="col-4 col-sm-3 col-md-2 col-xl-1"
            style={{ minWidth: "200px" }}
          >
            <label htmlFor="group_name" className="form-label ms-2 fw-bold">
              결재그룹 이름
            </label>
            <input
              type="text"
              name="group_name"
              className="form-control"
              required
              value={dataToCreate.group_name || ""}
              onChange={handleChange}
            />
          </div>

          <div
            className="col-4 col-sm-3 col-md-2 col-xl-1"
            style={{ minWidth: "300px" }}
          >
            <label htmlFor="description" className="form-label ms-2 fw-bold">
              결재그룹 설명
            </label>
            <input
              type="text"
              name="description"
              className="form-control"
              value={dataToCreate.description || ""}
              onChange={handleChange}
            />
          </div>

          <div
            className="col-4 col-sm-3 col-md-2 col-xl-1"
            style={{ minWidth: "80px" }}
          >
            <div className="mb-2">
              <br></br>
            </div>
            <button name="submit" className="btn btn-primary">
              {isAdded ? "완료" : "생성"}
            </button>
          </div>
        </div>
      </form>

      <div className="row mt-3">
        <div className="col" style={{ minWidth: "800px", overflowX: "auto" }}>
          <table className="table table-bordered text-center align-middle">
            <thead>
              <tr className="table-secondary">
                <th style={{ width: "10%" }}>순서</th>
                <th style={{ width: "22%" }}>그룹이름</th>
                <th style={{ width: "27%" }}>설명</th>
                <th style={{ width: "24%" }}>결재자</th>
                <th style={{ width: "17%" }}>관리</th>
              </tr>
            </thead>
            <tbody>
              {dataToSaveRows &&
                dataToSaveRows.map((row, index) => (
                  <tr key={row.id}>
                    <td>
                      <input
                        className="input-like-span"
                        required
                        type="number"
                        name="order"
                        value={row?.order}
                        onChange={(e) => handleTrChange(index, e)}
                      />
                    </td>
                    <td>
                      <input
                        className="input-like-span"
                        required
                        type="text"
                        name="group_name"
                        value={row?.group_name}
                        onChange={(e) => handleTrChange(index, e)}
                      />
                    </td>
                    <td>
                      <input
                        className="input-like-span"
                        required
                        type="text"
                        name="description"
                        value={row?.description}
                        onChange={(e) => handleTrChange(index, e)}
                      />
                    </td>
                    <td>
                      {approvalGroupMembersData &&
                        approvalGroupMembersData
                          .filter(
                            (r) => r.group_id.toString() === row.id.toString()
                          )
                          ?.map((member) => (
                            <span key={member.id || member.user}>
                              {member.stage && member.stage + ":"}
                              {member.user?.fullname}
                            </span>
                          ))}
                    </td>
                    <td>
                      <button
                        type="button"
                        id={row && row.id}
                        onClick={handleMembers}
                        className="btn btn-sm p-1 me-1 btn-success"
                      >
                        결재자
                      </button>
                      {row?.order < 1 ? (
                        ""
                      ) : (
                        <>
                          <button
                            type="button"
                            onClick={() => handleSave(index)}
                            className="btn btn-sm p-1 me-1 btn-primary"
                          >
                            {savedRows.includes(row.id) ? "저장완료" : "저장"}
                          </button>
                          {confirmDelete.includes(row.id) ? (
                            <>
                              <button
                                type="button"
                                onClick={() => handleDelete(row)}
                                className="btn btn-sm p-1 btn-danger"
                              >
                                확인
                              </button>
                              <button
                                type="button"
                                onClick={() => handleCancelDelete()}
                                className="btn btn-sm p-1 btn-secondary"
                              >
                                취소
                              </button>
                            </>
                          ) : (
                            <button
                              type="button"
                              onClick={() => handleConfirmDelete(row)}
                              className="btn btn-sm p-1 btn-warning"
                            >
                              삭제
                            </button>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
