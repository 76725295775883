import React, { Suspense, lazy, useMemo } from "react";

const DynamicComponentsRenderer = ({
  components,
  customizedContents,
  keyData,
  modifyMode,
  setIsLoadingModal,
  handleChangeToSave,
  row,
  componentsToFormId,
  dynamicState,
  approvalData,
  keyArray,
  activeMenu,
  styleSettings,
}) => {
  const Component = useMemo(
    () =>
      lazy(() =>
        import(
          `./${components.component_category}/${components.component_filename}`
        )
      ),
    [components.component_category, components.component_filename]
  );
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component
        contents={customizedContents ? customizedContents : components.contents}
        keyData={keyData}
        modifyMode={modifyMode}
        setIsLoadingModal={setIsLoadingModal}
        handleChangeToSave={handleChangeToSave}
        row={row || {}}
        componentsToFormId={componentsToFormId}
        dynamicState={dynamicState}
        approvalData={approvalData}
        keyArray={keyArray}
        activeMenu={activeMenu}
        styleSettings={styleSettings}
      />
    </Suspense>
  );
};

export default DynamicComponentsRenderer;
