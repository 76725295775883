export const fontList = [
  { label: "기본", value: "sans-serif" },
  { label: "본고딕", value: "'Noto Sans', sans-serif" },
  { label: "고딕A1", value: "'Gothic A1', sans-serif" },
  { label: "검은고딕", value: "'Black Han Sans', sans-serif" },
  { label: "본명조", value: "'Noto Serif', serif" },
  { label: "도현", value: "'Do Hyeon', sans-serif" },
  { label: "주아", value: "'Jua', sans-serif" },
  { label: "나눔필기체", value: "'Nanum Pen Script', cursive" },
  { label: "Nunito", value: "'Nunito', sans-serif" },
  { label: "Montserrat", value: "'Montserrat', sans-serif" },
  { label: "Oswald", value: "'Oswald', sans-serif" },
  { label: "바탕", value: "'Batang', serif" },
  { label: "돋움", value: "'Dotum', sans-serif" },
  { label: "굴림", value: "'Gulim', sans-serif" },
  { label: "궁서", value: "'Gungsuh', serif" },
];

export const attendanceOptions = [
  { label: "출석", value: "present" },
  { label: "결석", value: "absent" },
];

// 객체를 변수에 할당
const settingOptions = {
  fontList,
  attendanceOptions,
};

// 변수로 default export
export default settingOptions;
