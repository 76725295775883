import React from "react";
import { useLocation } from "react-router-dom";
import MainSidebar from "./mainNavbar/MainSidebar";
import MainNavbar from "./mainNavbar/MainNavbar";
import ToastCenterTop from "./ToastCenterTop";
import AlertModal from "./AlertModal";
import ToastCenterBottom from "./ToastCenterBottom";

const Layout = ({ children }) => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/login" && <MainNavbar />}
      <ToastCenterTop />
      <ToastCenterBottom />
      <AlertModal />
      <div className="d-flex">
        {location.pathname !== "/login" && <MainSidebar />}
        <main>{children}</main>
      </div>
    </>
  );
};

export default Layout;
