import { useQuery } from "@tanstack/react-query";
import VariousRecordListsService from "../service/variousRecordLists";
import HttpClient from "../network/http";
import { fetchCsrfToken } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
const variousRecordListsService = new VariousRecordListsService(httpClient);

export default function useVariousRecordLists(conditions) {
  // const queryClient = useQueryClient();
  // console.log("conditions", conditions);
  //users db에서 variousRecordLists join해서 가져온다.
  const variousRecordListsQuery = useQuery(
    ["variousRecordLists"],
    () => variousRecordListsService.findAllVariousRecordLists(conditions),
    {
      enabled: !!conditions?.schoolid,
      // staleTime: 1000 * 60 * 10, //10분,
      // onSuccess: (data) => {
      //   console.log("variousRecordLists Query Data:", data);
      // },
    }
  );

  // const createVariousRecordLists = useMutation(
  //   (data) => variousRecordListsService.createVariousRecordLists(data),
  //   {
  //     onSuccess: () => queryClient.invalidateQueries(["variousRecordLists"]),
  //     onError: (error) => {
  //       console.error("Error creating:", error);
  //     },
  //   }
  // );

  // const updateVariousRecordLists = useMutation(
  //   (data) => variousRecordListsService.updateVariousRecordLists(data),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["variousRecordLists"]);
  //     },
  //     onError: (error) => {
  //       // Handle the failure by displaying an error message or performing other actions
  //       console.error("Error updating variousRecordLists:", error);
  //       alert(error);
  //       // Add your error handling logic here
  //     },
  //   }
  // );

  // const deleteVariousRecordLists = useMutation(
  //   (data) => variousRecordListsService.deleteVariousRecordLists(data),
  //   {
  //     onSuccess: () => {
  //       // Refetch the query first
  //       queryClient.invalidateQueries(["variousRecordLists"]);
  //     },
  //   }
  // );

  return {
    variousRecordListsQuery,
    // createVariousRecordLists,
    // updateVariousRecordLists,
    // deleteVariousRecordLists,
  };
}
