export default class UniComponentsService {
  constructor(http) {
    this.http = http;
  }

  //메뉴이름 가져오기 (메뉴이름 테이블)

  async readCommonMenus(schoolId) {
    const queryParams = Object.entries(schoolId)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return this.http.fetch(`/api/menunames/?${queryParams}`, {
      method: "GET",
    });
  }

  // async readSchoolMenus(schoolid) {
  //   return this.http.fetch(`/api/menunames/${schoolid}`, {
  //     method: "GET",
  //   });
  // }

  //학교 아이디에 맞춰서 학교 로고 가져오기 (학교관리자 테이블)
  //로그인/개인 정보 가져오기 (로그인 정보에 따른 개인 정보 테이블)
  //권한 정보 가져오기 (권한 정보 테이블)
  //알림 정보 가져오기 (알림 정보 테이블)
}
