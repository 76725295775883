import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import MenuDropdown from "./MenuDropdown";
import MyProfile from "./MyProfile";
// import Notifications from "./Notifications";
import useUniComponent from "../../hooks/useUniComponent";
import { useAuthContext } from "../../context/AuthContext";

export default function MainSidebar() {
  const { user } = useAuthContext();

  const {
    readCommonMenus: { isLoading, error, data: beforeMenus },
  } = useUniComponent({ schoolid: user?.schoolid });

  const location = useLocation();

  useEffect(() => {
    setSidebarVisible(false);
  }, [location]);

  let commonMenus = [];
  let userRole;

  if (beforeMenus && user) {
    if (user.role === "admin") {
      userRole = "teacher";
      commonMenus = beforeMenus;
    } else {
      commonMenus = beforeMenus.filter(
        (menu) =>
          menu.categoryname !== "관리자" && menu.categoryname !== "교직원관리"
      );
      userRole = user.role;
    }

    commonMenus = commonMenus.filter(
      (menu) =>
        menu.display === true &&
        (menu.user_role === userRole || menu.user_role === "all")
    );
  }

  // State to track sidebar visibility
  const [sidebarVisible, setSidebarVisible] = useState(false);

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <>
      <aside
        className={`sidebar bg-light vh-100 ${
          sidebarVisible ? "" : ""
        } d-sm-none`}
        style={{
          width: sidebarVisible ? "150px" : "0px",
          padding: sidebarVisible ? "10px" : "0px",
          position: "fixed",
          zIndex: 2,
        }}
      >
        <div
          className="sidebar-toggle"
          style={{ position: "absolute", top: 10, left: 10 }}
        >
          <button onClick={toggleSidebar} className="sidebar-toggle-btn">
            <FaBars />
          </button>
        </div>
        <div
          className={`sidebar-contents h-100 d-flex flex-column justify-content-around ${
            sidebarVisible ? "" : "d-none"
          }`}
        >
          <div
            className="sidebar-header text-center mt-3 mb-3 me-5 d-flex flex-column justify-content-around"
            style={{
              height: "140px",
            }}
          >
            <MyProfile subMenus={commonMenus} user={user} />
            {/* <Notifications subMenus={commonMenus} user={user} /> */}
          </div>
          <div className="sidebar-content flex-grow-1">
            {isLoading && <p>Loading...</p>}
            {error && error.toString()}
            {commonMenus &&
              commonMenus
                .filter(
                  (menu) =>
                    menu.components === "Appbar" &&
                    menu.parentcategory === "parent"
                )
                .sort((a, b) => a.categoryorder - b.categoryorder)
                .map((menu) => (
                  <MenuDropdown
                    key={menu.id}
                    parentMenu={menu}
                    subMenus={commonMenus}
                    user={user}
                  />
                ))}
            <div>
              <button
                className="btn btn-sm"
                type="button"
                key="feedback"
                onClick={() =>
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLSev67sNIFU22Ze3ADyb27mTBnKql_pOri8SyObqlnIH4lJw0A/viewform?usp=sf_link",
                    "_blank"
                  )
                }
              >
                에러/의견제출
              </button>
            </div>
          </div>
          <div className="sidebar-footer text-center mt-3 mb-5">
            <Link
              to={
                user?.schoolid ? `/${user.schoolid}/${userRole}/dashboard` : "/"
              }
            >
              <img alt="에듀씨드" src="/images/eduseedLogo.png" height="50px" />
            </Link>
          </div>
        </div>
      </aside>
      {sidebarVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
          onClick={toggleSidebar}
        />
      )}
    </>
  );
}
