import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../network/http";
import { fetchCsrfToken } from "../context/AuthContext";
import ComponentsToFormsService from "../service/componentsToForms";

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
const componentsToFormsService = new ComponentsToFormsService(httpClient);

export default function useComponentsToForms(conditions) {
  const queryClient = useQueryClient();
  // console.log("useComponentsToForms conditions", conditions);

  const queryKey = [
    "componentsToForms",
    conditions?.schoolid,
    conditions?.form_id,
  ];

  const componentsToFormsQuery = useQuery(
    queryKey,
    () => componentsToFormsService.findAllComponentsToForms(conditions),
    {
      enabled: !!conditions?.schoolid && !!conditions?.form_id,
      // staleTime: 1000 * 60 * 30, //30분,
      // onSuccess: (data) => {
      //   console.log("componentsToForms Query Data:", data);
      // },
    }
  );

  const createOrUpdateComponentsToForms = useMutation(
    (data) => componentsToFormsService.createOrUpdateComponentsToForms(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["componentsToForms", conditions]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  const createOrUpdateComponentsToFormsBulk = useMutation(
    (data) =>
      componentsToFormsService.createOrUpdateComponentsToFormsBulk(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["componentsToForms", conditions]),
      onError: (error) => {
        console.error("Error creatingBulk:", error);
      },
    }
  );

  const deleteComponentsToForms = useMutation(
    (data) => componentsToFormsService.deleteComponentsToForms(data),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["componentsToForms", conditions]);
      },
    }
  );

  const deleteVariousStudentRecordOne = useMutation(
    (id) => componentsToFormsService.deleteVariousStudentRecordOne(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["componentsToForms", conditions]);
      },
    }
  );

  return {
    componentsToFormsQuery,
    createOrUpdateComponentsToForms,
    createOrUpdateComponentsToFormsBulk,
    deleteComponentsToForms,
    deleteVariousStudentRecordOne,
  };
}
