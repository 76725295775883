import React from "react";
import DOMPurify from "dompurify";
import styles from "./CSS/ToastCenterBottom.module.css";
import { useUIContext } from "../context/UIContext";

export default function ToastCenterBottom() {
  const { toastCenterBottom } = useUIContext();

  if (!toastCenterBottom.visible) return null;

  return (
    <div
      className={styles.toastCenterBottom}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(toastCenterBottom.message),
      }}
    />
  );
}
