import React from "react";
import DropdownSchoolYear from "./DropdownSchoolYear";
import DropdownHierarchyOne from "./DropdownHierarchyOne";
import DropdownHierarchyTwo from "./DropdownHierarchyTwo";
import DropdownHierarchyThree from "./DropdownHierarchyThree";
import DropdownUniqueUsersInDisplayData from "./DropdownUniqueUsersInDisplayData";
import DropdownTermDate from "./DropdownTermDate";

const FiltersBar = ({
  settingData,
  handleDropdownChange,
  filters,
  studentList,
  validateInput,
  termPeriodData,
  termId,
  currentPeriod,
  disabled,
  showUsername,
  isTitle,
}) => (
  <>
    <div style={{ width: "95px" }} className="me-1">
      <DropdownSchoolYear
        settingData={settingData}
        handleDropdownChange={handleDropdownChange}
        schoolYearId={filters.schoolYearId}
        allYears={false}
        disabled={disabled}
      />
    </div>
    <div style={{ width: "95px" }} className="me-1">
      <DropdownHierarchyOne
        settingData={settingData}
        handleDropdownChange={handleDropdownChange}
        schoolYear={filters.schoolYear}
        hierarchyOneId={filters.hierarchyOneId}
        disabled={disabled}
      />
    </div>
    <div style={{ width: "95px" }} className="me-1">
      <DropdownHierarchyTwo
        settingData={settingData}
        handleDropdownChange={handleDropdownChange}
        schoolYear={filters.schoolYear}
        hierarchyOneId={filters.hierarchyOneId}
        hierarchyTwoId={filters.hierarchyTwoId}
        disabled={disabled}
      />
    </div>
    <div style={{ width: "95px" }} className="me-1">
      <DropdownHierarchyThree
        settingData={settingData}
        handleDropdownChange={handleDropdownChange}
        schoolYear={filters.schoolYear}
        hierarchyTwoId={filters.hierarchyTwoId}
        hierarchyThreeId={filters.hierarchyThreeId}
        disabled={disabled}
      />
    </div>

    <div style={{ width: "95px" }} className="me-1">
      <DropdownUniqueUsersInDisplayData
        displayData={studentList}
        displayKey={"fullname"}
        valueKey={"username"}
        filters={{ organization_path: filters.organPathForSort }}
        uniqueKey={"username"}
        sortKey={"studentno"}
        isDesc={false}
        handleDropdownChange={handleDropdownChange}
        selectedUser={filters.selectedStudent}
        selectName={"students"}
        styleClass={"form-control"}
        dropdownTitle={"학생"}
        required={true}
        highLight={validateInput?.user}
        disabled={disabled}
        isTitle={isTitle}
      />
      {showUsername && (
        <input
          readOnly
          type="text"
          className="input-like-span p-0 m-0"
          value={filters.selectedStudent || ""}
        />
      )}
    </div>
    {termPeriodData && termPeriodData.length > 0 && (
      <>
        <div style={{ width: "95px" }} className="me-1">
          <DropdownTermDate
            termPeriodData={termPeriodData}
            handleDropdownChange={handleDropdownChange}
            termId={termId}
            schoolYearId={filters.schoolYearId}
            hierarchyOneId={filters.hierarchyOneId}
            hierarchyTwoId={filters.hierarchyTwoId}
            disabled={disabled}
            highLight={validateInput?.term}
            isTitle={isTitle}
          />
        </div>
        <div style={{ width: "155px" }} className="me-1">
          <input
            type="text"
            className="form-control pt-2 pb-2 ps-1 pe-1"
            readOnly
            value={
              currentPeriod
                ? currentPeriod.start_date + "~" + currentPeriod.end_date
                : ""
            }
            style={{ fontSize: "13px" }}
          />
        </div>
      </>
    )}
  </>
);

export default FiltersBar;
