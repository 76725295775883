import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { validateByField } from "../../../util/validate";
import HelpHyperLink from "../../../unicomponents/HelpHyperLink";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
// import {
//   googleLogin,
//   googleLogout,
//   onUserStateChange,
// } from "../../../api/firebase";

export default function LoginMain() {
  // const [googleId, setGoogleId] = useState();
  const [message, setMessage] = useState("");
  const [selectedRole, setSelectedRole] = useState("noSelected");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedUsername, setSelectedUsername] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const storedRole = localStorage.getItem("selectedRole");
    if (storedRole) {
      setSelectedRole(storedRole);
      setUserInfo((prev) => ({ ...prev, role: storedRole }));
    }

    const storedSelectedSchool = localStorage.getItem("selectedSchool");
    if (storedSelectedSchool) {
      setSelectedSchool(storedSelectedSchool);
      setUserInfo((prev) => ({ ...prev, schoolid: storedSelectedSchool }));
    }

    const storedUsername = localStorage.getItem("selectedUsername");
    if (storedUsername) {
      setSelectedUsername(storedUsername);
      setUserInfo((prev) => ({ ...prev, username: storedUsername }));
    }
  }, []); // Empty dependency array to run the effect only once on mount

  // const handleGoogleLogin = (e) => {
  //   e.preventDefault();
  //   googleLogin().then(setGoogleId);

  //   //저기 setGoogleId가 아니라 db에서 구글아이디(이메일만이 아니라 uid :"bLxSLMuT41NxR0ThGQqDn85P32A2") 매칭하는 것으로 바꿔야 한다.
  //   //확인되면 대시보드로, 없으면 없다고 알려주기
  // };

  // const handleGoogleLogout = (e) => {
  //   e.preventDefault();
  //   googleLogout().then(setGoogleId);
  // };

  // useEffect(() => {
  //   onUserStateChange(setGoogleId);
  // }, []);

  const navigate = useNavigate();
  const { login } = useAuthContext();

  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
    schoolid: "",
    role: "",
  });

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      setSelectedUsername(value);
    }

    if (name === "schoolid") {
      setSelectedSchool(value);
    }

    if (name === "role") {
      setSelectedRole(value);
    }

    setUserInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    // console.log("userInfo", userInfo);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (validateByField("input")) {
      try {
        await login(userInfo);
        // console.log("userInfoAfterLogin", userInfo);
        setSelectedRole(userInfo.role);
        localStorage.setItem("selectedRole", userInfo.role);
        localStorage.setItem("selectedUsername", userInfo.username);
        localStorage.setItem("selectedSchool", userInfo.schoolid);
        if (userInfo.role === "admin") {
          navigate(`/${userInfo.schoolid}/teacher/dashboard`);
        } else {
          navigate(`/${userInfo.schoolid}/${userInfo.role}/dashboard`);
        }
        setMessage(null);
      } catch (err) {
        console.log(err);
        if (err) {
          setMessage(err.toString());
        } else {
          setMessage(
            "알 수 없는 오류가 발생했습니다. 새로고침 후 다시 시도하고, 오류가 반복되면 관리자에게 문의하세요."
          );
        }
      }
    } else {
      setMessage("입력조건을 확인하세요");
    }
  };
  return (
    <div className="loginMain m-5">
      <main className="form-signin">
        <form className="needs-validation" noValidate>
          <div className="d-flex">
            <h1 className="h3 mb-4 fw-normal">로그인</h1>
            <HelpHyperLink link={"https://youtu.be/9l-KLc1bclM"} />
          </div>
          <div className="form-floating mb-2">
            <select
              value={selectedRole}
              className="form-control"
              id="role"
              name="role"
              placeholder="사용자 유형"
              onChange={handleUserChange}
              required
            >
              <option value="noSelected" disabled>
                사용자 유형
              </option>
              <option value="teacher">교직원</option>
              <option value="student">학생</option>
              <option value="parent">학부모</option>
              <option value="admin">관리자</option>
            </select>
            <div className="valid-feedback">사용자 유형을 선택하세요.</div>
            <label>사용자 유형</label>
          </div>
          <div className="form-floating mb-2">
            <input
              type="text"
              className="form-control"
              id="schoolid"
              name="schoolid"
              placeholder="myschool"
              value={selectedSchool}
              onChange={handleUserChange}
              required
            />
            <label>School ID</label>
          </div>

          <div className="form-floating mb-2">
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              placeholder="사용자아이디"
              value={selectedUsername}
              onChange={handleUserChange}
              required
            />
            <div className="valid-feedback">사용자 아이디를 입력하세요.</div>
            <label>사용자 아이디</label>
          </div>
          <div className="form-floating mb-2">
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                name="password"
                onChange={handleUserChange}
                required
              />
              <button
                type="button" // This prevents form submission
                className="btn btn-outline-secondary"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <MdOutlineVisibilityOff size={20} />
                ) : (
                  <MdOutlineVisibility size={20} />
                )}
              </button>
            </div>
          </div>
          <button
            className="w-100 btn btn-lg btn-primary"
            onClick={handleClick}
          >
            Sign in
          </button>
          {message && (
            <p className="bg-warning mt-2 p-1 rounded fw-bold text-center">
              {message}
            </p>
          )}

          {/* <div className="mt-2 text-end">
            <button
              className="w-100 btn btn-lg btn-info"
              onClick={handleGoogleLogin}
            >
              구글아이디로 로그인하기(테스트)
            </button>
          </div>
          <br></br>
          {!googleId && "구글 아이디 로그인 안 함"}
          {googleId && (
            <div className="mt-2 text-end">
              <button
                className="w-100 btn btn-lg btn-info"
                onClick={googleLogout}
              >
                구글아이디 로그아웃하기
              </button>
            </div>
          )} */}
          <p className="mt-3 mb-3 text-muted">EduSeed Since 2019</p>
        </form>
      </main>
    </div>
  );
}

// export default LoginMain;
