import React, { useState } from "react";
import useEducationalActivities from "../../../hooks/useEducationalActivities";
import { useUIContext } from "../../../context/UIContext";
import { useQueryClient } from "@tanstack/react-query";

export default function EbomActivitiesByFieldsTable({
  rows,
  setRows,
  activeMenu,
  thisYearTerm,
  subjectSorts,
  selectedTerm,
}) {
  //submit 누르면 로딩 뜨도록
  const { setIsLoadingModal } = useUIContext();

  const [savedRows, setSavedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState([]);

  //submit를 위한
  const { updateEducationalActivities, deleteEducationalActivities } =
    useEducationalActivities();

  const queryClient = useQueryClient();

  const handleTrChange = (index, event) => {
    const field = event.target.name;

    // 태그 필드에 대한 처리
    // extra_attributes 또는 기타 필드에 대한 처리
    const value = field.includes("extra_attributes")
      ? {
          ...rows[index].extra_attributes,
          [event.target.id]: event.target.value,
        }
      : event.target.value;

    setRows((currentRows) =>
      currentRows.map((row, rowIndex) =>
        rowIndex === index ? { ...row, [field]: value, isModified: true } : row
      )
    );
  };

  const handleSubmit = (index) => {
    const dataSet = rows[index];

    if (!dataSet.isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      return;
    }

    // 변경된 데이터 세트 처리 로직...

    setIsLoadingModal(true);
    updateEducationalActivities.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, dataSet.id]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });

    // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
    setRows((currentDataSets) =>
      currentDataSets.map((dataSet, dataSetIndex) =>
        dataSetIndex === index ? { ...dataSet, isModified: false } : dataSet
      )
    );
  };

  const handleConfirmDelete = (row) => {
    setConfirmDelete((prev) => [...prev, row.id]);
  };

  const handleCancelDelete = () => {
    setConfirmDelete([]);
  };

  const handleDelete = (row) => {
    if (
      window.confirm(
        "정말 삭제하시겠습니까? 이 활동에 속한 모든 학생기록도 삭제됩니다."
      )
    ) {
      const id = row.id;
      setIsLoadingModal(true);
      deleteEducationalActivities.mutate(id, {
        onSuccess: (successMessage) => {
          console.log(successMessage);
          setIsLoadingModal(false);
          setConfirmDelete([]);
          queryClient.invalidateQueries(["educationalActivities"]);
          setRows((rows) => rows.filter((row) => row.id !== id));
        },
        onError: (error) => {
          setIsLoadingModal(false);
          setConfirmDelete([]);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(
            "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
              error.message
          );
        },
      });
    } else {
      setConfirmDelete([]);
      console.log("취소되었습니다.");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="container-fluid">
        <table
          className="table table-bordered"
          style={
            activeMenu?.options?.length > 0 &&
            activeMenu?.options[0]?.[activeMenu.schoolid]
              ? { fontSize: "smaller" }
              : {}
          }
        >
          <thead>
            <tr className="table-secondary">
              <th style={{ width: "60px" }}>과정</th>
              <th style={{ width: "90px" }}>영역</th>
              <th style={{ minWidth: "80px", width: "150px" }}>활동명</th>
              <th style={{ minWidth: "60px", width: "65px" }}>학기</th>
              {/* <th style={{ width: "100px" }}>기준날짜</th> */}
              <th style={{ width: "70px" }}>시간</th>
              <th style={{ minWidth: "260px" }}>세부내용</th>
              <th style={{ width: "60px" }}>추가</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <Trs
                key={row.id}
                row={row}
                index={index}
                handleTrChange={handleTrChange}
                handleSubmit={handleSubmit}
                savedRows={savedRows}
                handleDelete={handleDelete}
                confirmDelete={confirmDelete}
                handleConfirmDelete={handleConfirmDelete}
                handleCancelDelete={handleCancelDelete}
                thisYearTerm={thisYearTerm}
                subjectSorts={subjectSorts}
              />
            ))}
          </tbody>
        </table>
      </form>
    </div>
  );
}

function Trs({
  row,
  index,
  handleTrChange,
  handleSubmit,
  savedRows,
  handleDelete,
  confirmDelete,
  handleConfirmDelete,
  handleCancelDelete,
  thisYearTerm,
  subjectSorts,
}) {
  return (
    <React.Fragment>
      <tr style={{ borderBottom: "3px solid #000" }}>
        <td>{row.schoolStage}</td>
        <td>
          <select
            className="select-like-span"
            name="extra_attributes"
            id="field"
            value={row.extra_attributes?.field}
            onChange={(e) => handleTrChange(index, e)}
          >
            {subjectSorts.map((field, key) => (
              <option key={key} value={field}>
                {field}
              </option>
            ))}
          </select>
        </td>
        <td className="p-0">
          <textarea
            type="text"
            className="input-like-span"
            name="title"
            value={row.title}
            onChange={(e) => handleTrChange(index, e)}
            rows={3}
          />
        </td>
        <td>
          <select
            className="select-like-span"
            name="extra_attributes"
            id="termName"
            value={row.extra_attributes?.termName}
            onChange={(e) => handleTrChange(index, e)}
          >
            {thisYearTerm &&
              thisYearTerm.map((arr, key) => (
                <option key={key} value={arr.term_name}>
                  {arr.term_name}
                </option>
              ))}
          </select>
        </td>
        {/* <td>
          <input
            type="date"
            className="input-like-span"
            name="start_date"
            value={row.start_date}
            onChange={(e) => handleTrChange(index, e)}
            style={{ width: "105px" }}
          />
        </td> */}
        <td>
          <input
            type="text"
            className="input-like-span"
            name="extra_attributes"
            id="hours"
            value={row.extra_attributes?.hours}
            onChange={(e) => handleTrChange(index, e)}
          />
        </td>
        <td className="p-0">
          <textarea
            type="text"
            className="input-like-span"
            name="content"
            value={row.content}
            onChange={(e) => handleTrChange(index, e)}
            rows={3}
          />
        </td>
        <td width={60} valign="middle" className="p-1 text-center">
          <button
            type="button"
            onClick={() => handleSubmit(index)}
            className="btn btn-sm p-1 btn-primary"
          >
            {savedRows.includes(row.id) ? "저장완료" : "저장"}
          </button>
          {confirmDelete.includes(row.id) ? (
            <>
              <button
                type="button"
                onClick={() => handleDelete(row)}
                className="btn btn-sm p-1 btn-danger mt-1"
              >
                확인
              </button>
              <button
                type="button"
                onClick={() => handleCancelDelete()}
                className="btn btn-sm p-1 btn-secondary mt-1"
              >
                취소
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={() => handleConfirmDelete(row)}
              className="btn btn-sm p-1 btn-warning mt-1"
            >
              삭제
            </button>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
}
