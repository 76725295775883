import { useQuery } from "@tanstack/react-query";
import HttpClient from "../network/http";
import { fetchCsrfToken } from "../context/AuthContext";
import UniComponentsService from "../service/unicomponents";

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
const uniComponentsService = new UniComponentsService(httpClient);

export default function useUniComponent(schoolId = {}) {
  const readCommonMenus = useQuery(
    ["commonMenus", schoolId],
    () => uniComponentsService.readCommonMenus(schoolId),
    {
      enabled: !!schoolId.schoolid,
      staleTime: 1000 * 60 * 10, //10분,
    }
  );

  //이거는 아직 안 쓰고 있구나. 조만간 수정해야겠군.
  // const readSchoolMenus = useQuery(
  //   ["schoolMenus"],
  //   () => uniComponentsService.readSchoolMenus,
  //   {
  //     staleTime: 1000 * 60 * 10, //10분,
  //   }
  // );

  return { readCommonMenus };
}
