import React from "react";
import styles from "./CSS/SideMenu.module.css";
import { settingOptions } from "../settingData";

//받을 props는 setState(해당 컴포넌트로 바꿔주는) 1개와
//리스트할 data(이동할 컴포넌트의 name과 화면에 표시될 label)만 있으면 된다.
export default function RightControlSidebar({
  styleSettings,
  handleStyleSettings,
}) {
  const fontList = settingOptions.fontList;

  // const fontList = [
  //   { label: "기본", value: "sans-serif" },
  //   { label: "본고딕", value: "'Noto Sans', sans-serif" },
  //   { label: "고딕A1", value: "'Gothic A1', sans-serif" },
  //   { label: "검은고딕", value: "'Black Han Sans', sans-serif" },
  //   { label: "본명조", value: "'Noto Serif', serif" },
  //   { label: "도현", value: "'Do Hyeon', sans-serif" },
  //   { label: "주아", value: "'Jua', sans-serif" },
  //   { label: "나눔필기체", value: "'Nanum Pen Script', cursive" },
  //   { label: "Nunito", value: "'Nunito', sans-serif" },
  //   { label: "Montserrat", value: "'Montserrat', sans-serif" },
  //   { label: "Oswald", value: "'Oswald', sans-serif" },
  //   { label: "바탕", value: "'Batang', serif" },
  //   { label: "돋움", value: "'Dotum', sans-serif" },
  //   { label: "굴림", value: "'Gulim', sans-serif" },
  //   { label: "궁서", value: "'Gungsuh', serif" },
  // ];
  return (
    <div className={`align-items-start bg-light ${styles.sideMenuRight}`}>
      <div className="p-2 w-100">
        <h4 className="text-center mt-3">스타일 조정</h4>
        {styleSettings?.fontFamily && (
          <div className="sidebar-option mt-3">
            <label>글씨체:</label>
            <select
              value={styleSettings.fontFamily}
              className="fontFamily form-control"
              onChange={handleStyleSettings}
            >
              {fontList.map((font) => (
                <option key={font.value} value={font.value}>
                  {font.label}
                </option>
              ))}{" "}
            </select>
          </div>
        )}

        {styleSettings?.fontSizes
          ?.sort(
            (a, b) =>
              (a.order ?? Number.MAX_SAFE_INTEGER) -
              (b.order ?? Number.MAX_SAFE_INTEGER)
          )
          .map((option) => (
            <div className="sidebar-option mt-3" key={option.name}>
              <label>{option.label}</label> <span>{option.value}px</span>
              <input
                type="range"
                min={option.min || 1}
                max={option.max || 100}
                value={option.value}
                className="fontSizes"
                name={option.name}
                onChange={handleStyleSettings}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
